<template>
  <div class="app-content content" :class="[{'show-overlay': $store.state.app.shallShowOverlay}]">
    <div class="content-overlay"/>
    <div class="header-navbar-shadow"/>
    <div class="content-wrapper">
      <div class="row content-header">
        <div class="col-12 col-md-9 content-header-left mb-2">
          <div class="row breadcrumbs-top">
            <div class="col-12">
              <h2 class="float-left pr-1 mb-0">{{ $route.meta.pageTitle }}</h2>
            </div>
          </div>
        </div>
      </div>
      <div class="content-body">
        <b-card no-body>
          <div class="m-2">
            <b-nav tabs>
              <b-nav-item
                  :to="{ name: `${prefix}-configuration` }"
                  :active="$route.name === `${prefix}-configuration`"
              >
                Информация
              </b-nav-item>
              <b-nav-item
                  v-if="prefix === 'rozetka'"
                  class="text-capitalize"
                  :to="{ name: `${prefix}-categories` }"
                  :active="$route.name === `${prefix}-categories`"
              >
                Категории {{ prefix }}
              </b-nav-item>
              <b-nav-item
                  v-if="prefix === 'rozetka'"
                  :to="{ name: `${prefix}-brandstock-categories` }"
                  :active="$route.name === `${prefix}-brandstock-categories`"
              >
                Категории Brandstock
              </b-nav-item>
              <b-nav-item
                  v-if="prefix === 'rozetka'"
                  :to="{ name: `${prefix}-prices` }"
                  :active="$route.name === `${prefix}-prices`"
              >
                Цены
              </b-nav-item>
            </b-nav>
          </div>
          <router-view :external-service-id="externalServiceId" :prefix="prefix"/>
        </b-card>
      </div>
    </div>
  </div>
</template>

<script>
import { BCard, BNav, BNavItem } from 'bootstrap-vue';

export default {
  name: 'ExternalService',
  props: ['externalServiceId', 'prefix'],
  components: {
    BCard,
    BNav,
    BNavItem,
  },
};
</script>

<style scoped>
</style>
